import { IMenuItem } from ".";

import { Name } from "../components/BaseIcon";
import { goToVue } from "../integrationWithVue/goToVue";

export const AUTH_MENU_ITEMS: IMenuItem[] = [
  {
    label: {
      true: "המשלוחים שלי",
      false: "My shipments",
    },
    icon: Name.Box,
    color: "#284492",
    goTo: (event: any) => goToVue("/my-shipments", event),
  },
  {
    label: {
      true: "משלוח לבית",
      false: "Store to door",
    },
    goTo: (event: any) => goToVue("/store-to-door/onboarding", event),
    icon: Name.StoreToDoor
  },
  {
    label: {
      true: "פנקס הכתובות",
      false: "Address book",
    },
    icon: Name.Location,
    to: "/address-book",
  },
  {
    label: {
      true: "חיוב",
      false: "Billing",
    },
    icon: Name.Billing,
    goTo: (event: any) => goToVue("/billing/payment-methods", event),
  }
];
