import React from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { styled } from "@mui/system";
import { selectRtl } from "../../../../data/selectors";

import { BaseNewSelect } from "../../../BaseNewSelect";
import { Name } from "../../../BaseIcon";

import { signOut } from "../../../../scenes/auth/data/actions";
import { ISelectOption } from "../../../../interfaces";

import ManInCircleImage from "../../../../assets/images/man-in-circle.svg";
import brandColors from "../../../../css/brandColors.scss";
import styles from "./UserMenu.scss";
import { goToVue } from "../../../../integrationWithVue/goToVue";

const StyledRoot = styled("div")(
  () => `
  display: flex;
  align-items: center;
  color: ${brandColors.mainBlue};
  padding: 1px 16px;
  border: 1px solid ${brandColors.lightestBlue};
  border-radius: 999999px;
 `
);

const StyledAvatar = styled("img")(
  () => `
  width: 36px;
  height: 36px;
  cursor: pointer;
`
);

type Props = {
  isAuth: boolean;
  userName: string;
};

const UserMenu: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let rtl = useSelector(selectRtl);
  if (
    globalThis.location.pathname !== "/" &&
    !globalThis.location.pathname.startsWith("/he")
  ) {
    rtl = false;
  }

  const getMenuOptions = (): ISelectOption[] => {
    if (props.isAuth) {
      return [
        // {
        //   label: 'Billing address',
        //   value: 'billingAddress',
        //   leftIcon: Name.UserInSquare
        // },
        {
          label: rtl ? "המשלוחים שלי" : "My shipments",
          value: "shipments",
          leftIcon: Name.Box,
        },
        {
          label: rtl ? "משלוח לבית" : "Store to door",
          value: "storeToDoorOnboarding",
          leftIcon: Name.StoreToDoor,
        },
        {
          label: rtl ? "פנקס הכתובות" : "Address book",
          value: "address-book",
          leftIcon: Name.Location,
        },
        {
          label: rtl ? "חיוב" : "Billing",
          value: "billing",
          leftIcon: Name.Billing,
        },
        {
          label: rtl ? "בלוג" : "Blog",
          value: "blog",
          leftIcon: Name.Blog,
        },

        {
          label: rtl ? "עלינו" : "About us",
          value: "aboutUs",
          leftIcon: Name.AboutUs,
        },
        {
          label: rtl ? "אודות Store to Door" : "About Store to door",
          value: "storeToDoor",
          leftIcon: Name.AboutUs,
        },
        {
          label: rtl ? "התנתק" : "Sign out",
          value: "signOut",
          leftIcon: Name.Exit,
        }
      ];
    } else {
      return [
        {
          label: rtl ? "בלוג" : "Blog",
          value: "blog",
        },
        {
          label: rtl ? "עלינו" : "About us",
          value: "aboutUs",
        },
        {
          label: rtl ? "אודות Store to Door" : "About Store to door",
          value: "storeToDoor",
        },
        {
          label: rtl ? "להתחבר" : "Sign in",
          value: "signIn",
        },
        {
          label: rtl ? "להירשם" : "Sign up",
          value: "signUp",
        },
      ];
    }
  };

  const handleChangeMenu = (option: any, event: any): void => {
    switch (option.value) {
      case "signIn":
        navigate("/auth?type=sign-in");
        break;
      case "signUp":
        navigate("/auth?type=sign-up");
        break;
      case "blog":
        goToVue("/blog", event);
        break;
      case "aboutUs":
        goToVue("/about-us", event);
        break;
      case "business":
        goToVue("/business", event);
        break;
      case "storeToDoor":
        goToVue("/store-to-door", event);
        break;
      case "storeToDoorOnboarding":
        goToVue("/store-to-door/onboarding", event);
        break;
      case "shipments":
        // navigate('/shipments')
        goToVue("/my-shipments", event);
        break;
      case "address-book":
        navigate("/address-book");
        break;
      case "billing":
        goToVue("/billing/payment-methods", event);
        break;
      case "signOut":
        dispatch(signOut());
    }
  };

  const getMenuLabel = (): string => {
    if (props.isAuth) {
      return props.userName;
    } else {
      return rtl ? "להתחבר" : "Sign in";
    }
  };

  return (
    <div dir="ltr">
      <StyledRoot>
        <BaseNewSelect
          options={getMenuOptions()}
          value={getMenuLabel()}
          onChange={handleChangeMenu}
          className={styles.select}
          padding="9px 7.95px 11px 7.95px"
          withoutLabelSelecting
          isRtl
          withoutHover
        />

        <StyledAvatar
          onClick={() => navigate("/profile")}
          src={ManInCircleImage}
          alt="avatar"
        />
      </StyledRoot>
    </div>
  );
};

export default UserMenu;
