import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Breakpoint } from "@mui/material";
import {selectLocale, selectRtl} from "../../data/selectors";

import { BaseLink } from "../BaseLink";
import { RightPanel } from "./components/RightPanel";
import { MobileMenu } from "./components/MobileMenu";

import { selectIsAuth } from "../../scenes/auth/data/selectors";
import { selectFirstName } from "../../data/selectors";
import { HeaderBtns } from "../../integrationWithVue/HeaderBtns";
import { goToVue } from "../../integrationWithVue/goToVue";
import { useIsResponsive } from "../../services/breakpoint/useIsResponsive";
import {
  Logo,
  StyledMobileHamburger,
  StyledToolbar,
  TheHeaderWrapper,
  StyledHamburgerLine,
} from "./styles/TheHeader.styled";
import styles from "./TheHeader.scss";

interface TheHeaderProps {
  maxWidthContainer?: Breakpoint;
}

const TheHeader = ({ maxWidthContainer }: TheHeaderProps) => {
  const locale = useSelector(selectLocale);
  const rtl = useSelector(selectRtl);
  const isAuth: boolean = useSelector(selectIsAuth);
  const userName: string = useSelector(selectFirstName);

  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (menuOpen) {
      document
        ?.querySelector("html")
        ?.setAttribute("style", "overflow: hidden");
    } else {
      document
        ?.querySelector("html")
        ?.setAttribute("style", "overflow: visible");
    }

    return () => {
      document
        ?.querySelector("html")
        ?.setAttribute("style", "overflow: visible");
    };
  }, [menuOpen]);

  const handleToggleMobileMenu = (): void => {
    setMenuOpen(!menuOpen);
  };

  const handleCloseMobileMenu = (event?: any): void => {
    setMenuOpen(false);
  };

  return (
    <TheHeaderWrapper>
      <Container maxWidth={maxWidthContainer}>
        <StyledToolbar>
          <div className={styles.content}>
            <BaseLink
              to={locale !== "en" ? `/${locale}` : "/"}
              onClick={() => handleCloseMobileMenu()}
            >
              {/* <BaseLink onClick={(event:any) => handleCloseMobileMenu(event)}> */}
              <Logo />
            </BaseLink>

            {<HeaderBtns isRtl={rtl} />}

            <RightPanel isAuth={isAuth} userName={userName} />

            <StyledMobileHamburger
              isOpen={menuOpen}
              onClick={() => handleToggleMobileMenu()}
            >
              <StyledHamburgerLine></StyledHamburgerLine>
              <StyledHamburgerLine></StyledHamburgerLine>
              <StyledHamburgerLine></StyledHamburgerLine>
            </StyledMobileHamburger>
          </div>
        </StyledToolbar>
      </Container>

      <MobileMenu
          isOpen={menuOpen}
          isAuth={isAuth}
          userName={userName}
          onClose={handleCloseMobileMenu} // Убрана ненужная обертка в стрелочную функцию
      />
    </TheHeaderWrapper>
  );
};

export default TheHeader;
