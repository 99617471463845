import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Container, Divider } from '@mui/material';
import { selectRtl } from '../../../../data/selectors';
import { BaseLink } from '../../../BaseLink';
import { Localization } from '../../../localization/Localization';
import { FOOTER_LINKS } from '../../../../constants/FooterLinks';
import { IMenuItem } from '../../../../constants';
import { AUTH_MENU_ITEMS } from "../../../../constants/AuthMenuLinks";
import { UNAUTH_MENU_ITEMS } from "../../../../constants/UnauthMenuLinks";
import { useGetMenuActions } from '../../../../services/useGetMenuActions';
import ManInCircleImage from '../../../../assets/images/man-in-circle.svg';
import BrandIcon from '../../../../assets/icons/brand-arrow.svg';
import { useIsResponsive } from '../../../../services/breakpoint/useIsResponsive';
import { goToVue } from '../../../../integrationWithVue/goToVue';
import { signOut } from '../../../../scenes/auth/data/actions';
import { Name } from '../../../../components/BaseIcon';
import {
  StyledAvatar,
  StyledAvatarIcon,
  StyledNameAndProfile,
  StyledAvatarText,
  StyledProfileBtn,
  StyledAvatarWrapper,
  StyledFooterLink,
  StyledMenuItem,
  StyledMenuItemIcon,
  StyledMenuItemText,
  StyledMenuList,
  StyledMenuListLine,
  StyledMobileMenu,
} from "./styles/MobileMenu.styled";
import styles from './MobileMenu.scss';

interface MobileMenuProps {
  isOpen: boolean;
  isAuth: boolean;
  userName?: string;
  onClose: () => void;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, isAuth, userName, onClose}) => {
  const rtl = useSelector(selectRtl);
  const location = useLocation();
  const { getMenuActions } = useGetMenuActions();
  const { isMobile } = useIsResponsive();

  const getMenuItems = (): IMenuItem[][] => {
    const items: IMenuItem[][] = [];

    if (isAuth) {
      items.push(AUTH_MENU_ITEMS);
    }
    items.push([
      {
        label: { true: 'לדף הבית', false: 'For business' },
        goTo: (event: React.MouseEvent) => goToVue('/business', event),
        icon: Name.Business,
      },
      {
        label: { true: 'אודות Store to Door', false: 'About Store to door' },
        goTo: (event: React.MouseEvent) => goToVue('/store-to-door', event),
        icon: Name.StoreToDoor,
      },
    ]);
    items.push(UNAUTH_MENU_ITEMS);
    if (process.env.REACT_APP_USERWAY_WIDGET && isMobile) {
      items.push([
        {
          label: { true: 'נְגִישׁוּת', false: 'Accessibility' },
          icon: Name.Accessibility,
          id: 'accessibility-widget',
          color: 'rgb(40, 68, 146)',
        },
      ]);
    }

    return items;
  };

  const handleMenuItemClick = (event: React.MouseEvent, menuItem: IMenuItem): void => {
    getMenuActions(event, menuItem);
    onClose();
  };

  const handleLogOut: IMenuItem = { label: 'Log Out', to: 'logOut' };

  const filteredFooterLinks: any = FOOTER_LINKS.filter(
      (link: any) => link.to !== "/contacts"
  );

  const isShowLanguage =
      (location.pathname.length === 3 && location.pathname.startsWith('/he')) ||
      (location.pathname.length === 3 && location.pathname.startsWith('/tr')) ||
      (location.pathname.length === 4 && location.pathname.startsWith('/he/')) ||
      (location.pathname.length === 4 && location.pathname.startsWith('/tr/')) ||
      location.pathname.length === 1;

  return (
      <StyledMobileMenu isOpen={isOpen}>
        <StyledAvatarWrapper>
          <BaseLink onClick={onClose} to={isAuth ? '/profile' : '/auth'}>
            <Container sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
              <StyledAvatar isAuth={!!userName}>
                <StyledAvatarIcon src={userName ? ManInCircleImage : BrandIcon} isAuth={!!userName} alt="" />
              </StyledAvatar>
              <StyledNameAndProfile>
                <StyledAvatarText>{isAuth ? userName : rtl ? 'התחברות' : 'Log in'}</StyledAvatarText>
                {isAuth && <StyledProfileBtn>{rtl ? 'ערוך פרופיל' : 'Edit profile'}</StyledProfileBtn>}
              </StyledNameAndProfile>
            </Container>
          </BaseLink>
        </StyledAvatarWrapper>

        {getMenuItems().map((menuItems, index) => (
            <StyledMenuList key={index}>
              {menuItems.map((menuItem: any) => (
                  <StyledMenuItem onClick={(event) => handleMenuItemClick(event, menuItem)} key={menuItem.to}>
                    <Container sx={{ display: 'flex', alignItems: 'center', margin: '15px 0', gap: '12px' }} id={menuItem.id}>
                      <StyledMenuItemIcon name={menuItem.icon} color={menuItem?.color} />
                      <StyledMenuItemText>{menuItem.label[String(rtl)]}</StyledMenuItemText>
                    </Container>
                  </StyledMenuItem>
              ))}
              <Container>
                <StyledMenuListLine />
              </Container>
            </StyledMenuList>
        ))}

        {isShowLanguage && (
            <Container>
              <StyledFooterLink>
                <Localization className={styles.localization} onCloseMenu={onClose} />
              </StyledFooterLink>
            </Container>
        )}

        {isAuth && (
            <StyledMenuList>
              <StyledMenuItem onClick={(event) => handleMenuItemClick(event, handleLogOut)}>
                <Container sx={{ display: 'flex', alignItems: 'center', margin: '13px 0', gap: '10px' }}>
                  <StyledMenuItemIcon name={Name.Exit} />
                  <StyledMenuItemText>{rtl ? 'להתנתק' : 'Log out'}</StyledMenuItemText>
                </Container>
              </StyledMenuItem>
            </StyledMenuList>
        )}

        <Divider style={{ opacity: 0 }} />

        {filteredFooterLinks.map((link: { to: any; noRtl: any; label: { [x: string]: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }; }, index: React.Key | null | undefined) => (
            <Container key={index}>
              <BaseLink onClick={onClose} to={link.to}>
                <div dir={link.noRtl ? 'ltr' : ''}>
                  <StyledFooterLink style={{ textAlign: rtl ? 'right' : 'initial' }}>
                    {link.label[String(rtl)]}
                  </StyledFooterLink>
                </div>
              </BaseLink>
            </Container>
        ))}
      </StyledMobileMenu>
  );
};

export default MobileMenu;