export const checkIsVueRoute = (goToUrl: any) => {

  const routesOnVue = [
    "my-shipments",
    "payments",
    "password/reset",
    "tracking",
    "blog",
    "about-us",
    "billing",
    "store-to-door",
    "store-to-door/onboarding",
    "store-to-door/my-addresses",
    "store-to-door/shipments",
    "business",
    "business/request"
  ];

  return routesOnVue.find((item) => {
    return goToUrl?.includes(item);
  });
};
