import React from 'react';
import styled from 'styled-components';
import TheHeaderExportVariables from "../components/TheHeader/TheHeaderExportVariables.scss";
import { goToVue } from "./goToVue";

// Стили для контейнера кнопок
const ButtonContainer = styled.div`
    display: flex;
    gap: 40px;
    width: 100%;
    margin-left: 20px;

    [dir="rtl"] & {
        margin-right: 20px;
        margin-left: 0;
    }

    @media (max-width: ${TheHeaderExportVariables.mediaMinWidthMenuHeader}) {
        display: none;
    }
`;

// Стили для кнопок
const StyledButton = styled.button`
  color: #284492;
  font-weight: 600;
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  &:hover {
    opacity: 0.8;
  }
`;

// Типы для пропсов и текста кнопок
type Props = {
    isRtl: boolean;
};

type ButtonText = {
    en: string;
    he: string;
};

const buttonTexts: Record<string, ButtonText> = {
    business: {
        en: "For Business",
        he: "לעסקים",
    },
    storeToDoor: {
        en: "Store to Door",
        he: "Store to Door",
    },
    blog: {
        en: "Blog",
        he: "בלוג",
    },
};

// Компонент HeaderBtns
export const HeaderBtns: React.FC<Props> = ({ isRtl }) => {
    const getButtonText = (key: keyof typeof buttonTexts) => {
        return isRtl ? buttonTexts[key].he : buttonTexts[key].en;
    };

    const handleButtonClick = (path: string, event: React.MouseEvent<HTMLButtonElement>) => {
        goToVue(path, event);
    };

    return (
        <ButtonContainer>
            <StyledButton onClick={(event) => handleButtonClick("/business", event)}>
                {getButtonText("business")}
            </StyledButton>
            <StyledButton onClick={(event) => handleButtonClick("/store-to-door", event)}>
                {getButtonText("storeToDoor")}
            </StyledButton>
            <StyledButton onClick={(event) => handleButtonClick("/blog", event)}>
                {getButtonText("blog")}
            </StyledButton>
        </ButtonContainer>
    );
};