import axios from "axios";
import {
  getStore,
  removeStore,
} from "../integrationWithVue/handleBrowserStore";
import { getErrorMessage } from "../services/getErrorMessage";
import { Notify } from "../services/notifications";
// import { setup } from 'axios-cache-adapter'
import { history } from "../services/history";

export const API = axios.create({
  // withCredentials: true,
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Shipper-App": "shipper-global",
  },
});

// API.interceptors.request.use((config: AxiosRequestConfig) => {
API.interceptors.request.use((config: any) => {
  if (process.env.REACT_APP_IS_MOBILE_APP === "true") {
    if (!("params" in config)) {
      const params = new URLSearchParams();
      config.params = params;
    }

    config.params.append("Shipper-App", "shipper-global");
  }

  try {
      // @ts-ignore
      if (window.sgaUtmTracker) {
          // @ts-ignore
          const jsonData = window.sgaUtmTracker.getJsonDataForApiRequest();
          config.headers["X-SG-ANALYTIC-EVENT-DATA"] = jsonData;
      }
  } catch (error) {
      console.error("Failed to get headers from sgaUtmTracker:", error);
  }

  // console.log('=axios token=', getStore('token'));

  config.headers.Authorization = `Bearer ${getStore("token")}`;

  return config;
});

export const responseMiddleware = (response: any): any => {
  if (response.data?.error) {
    return response.data;
  }

  return response.data.data;
};

API.interceptors.response.use(
  (config: any) => {
    return config;
  },
  (error: any) => {
    // console.error("=error=", error);
    switch (error?.response?.status) {
      case 400:
        return error.response;
      case 401:
        removeStore("token");
        // window.location.reload()
        // Notify.create(errorNotificationConfig(error.response.data.message))
        // router.push({ name: 'login' })
        return error.response;
      case 403:
        // router.push({ name: 'error403' })
        return error.response;
      case 404:
        return error.response;
      case 500:
        return error.response;
      default:
        throw error;
    }
  }
);

export default API;

// const LOGOUT_STATUSES = [401]

// export const logoutInterceptor = error => {
//   const status = error.response && error.response.status
//   if (!LOGOUT_STATUSES.includes(status)) {
//     return Promise.reject(error)
//   }
//   const { dispatch } = getStore()
//   dispatch(logoutTabs())
// }

// const successRequestInterceptor = (response: AxiosResponse<any>) => Promise.resolve(response);
// const failedRequestInterceptor = (response: AxiosResponse<any>) => Promise.reject(response);

// export const cacheApi = setup({
//   baseURL: config.webapiUrl,
//   withCredentials: true,

//   cache: {
//     maxAge: 12 * 60 * 60 * 1000,
//     exclude: {
//       methods: ['put', 'patch', 'delete'],
//       query: false
//     }
//   }
// })

// api.interceptors.request.use(timingInterceptor)
// cacheApi.interceptors.request.use(clientTypeInterceptor)

export const saveAddressForm = async ({ shipmentId, addressForm }: any) => {
  try {
    addressForm.address.phone = addressForm.address.phone.includes("+")
      ? addressForm.address.phone
      : "+" + addressForm.address.phone;

    const response = await API.post(
      `/shipments/${shipmentId}/addresses`,
      addressForm
    );

    if (response.status !== 200) {
      throw response.data.error;
    }

    return response.data.data;
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
    throw new Error("");
  }
};

export class RootRequests {
  static getProfile = async () => {
    const response = await API.get("/profile");
    // const response = {
    //   data: { data: { email: "2", first_name: "e", last_name: "r" } },
    // }; // ! Для теста, если сервер не отвечает

    return response;
  };
}

export class ShipmentAPI {
  static getShipment = async (id: string) => {
    const response = await API.get(`/shipments/${id}`);

    response.status === 404 && history.push(`/error404`);

    return responseMiddleware(response);
  };

  static getPickupCalendar = async (id: string) => {
    const response = await API.get(`/shipments/${id}/pickup-calendar`);

    return responseMiddleware(response);
  };

  static createShipment = async (formValues: any) => {
    const response = await API.post(`/shipments`, formValues);

    return responseMiddleware(response);
  };

  static getShipmentAdditionalPayments = async (id: any) => {
    const response = await API.get(`/shipments/${id}/payment-details`);

    return responseMiddleware(response);
  };

  static sendCoupon = async ({
    shipmentId,
    code,
  }: {
    shipmentId: string;
    code: string | number;
  }) => {
    const response = await API.post(`/shipments/${shipmentId}/coupon`, {
      code,
    });

    return response;
  };

  static deleteCoupon = async (id: any) => {
    const response = await API.delete(`/shipments/${id}/coupon`);

    return response;
  };
}
